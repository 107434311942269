import { useIsSubmitting } from 'remix-validated-form';
import { cn } from '@cardo/lib';
import { Button } from '../common/Button';

type SubmitButtonProps = {
  label: string;
  labelSubmitting: string;
  className?: string;
  name?: string;
  value?: string;
  formId: string;
  disabled?: boolean;
};

export function SubmitButton({
  label,
  labelSubmitting,
  className,
  name,
  value,
  formId,
  disabled,
}: SubmitButtonProps) {
  const isSubmitting = useIsSubmitting(formId);

  return (
    <Button
      type="submit"
      disabled={isSubmitting || disabled}
      loading={isSubmitting}
      primary={!disabled}
      large
      className={cn(className)}
      name={name}
      value={value}
    >
      {isSubmitting ? labelSubmitting : label}
    </Button>
  );
}
